:root {
  --toastify-color-light: #fff;
  --toastify-color-dark: #121212;
  --toastify-color-info: #3498db;
  --toastify-color-success: #07bc0c;
  --toastify-color-warning: #f1c40f;
  --toastify-color-error: #e74c3c;
  --toastify-color-transparent: #ffffffb3;
  --toastify-icon-color-info: var(--toastify-color-info);
  --toastify-icon-color-success: var(--toastify-color-success);
  --toastify-icon-color-warning: var(--toastify-color-warning);
  --toastify-icon-color-error: var(--toastify-color-error);
  --toastify-toast-width: 320px;
  --toastify-toast-background: #fff;
  --toastify-toast-min-height: 64px;
  --toastify-toast-max-height: 800px;
  --toastify-font-family: sans-serif;
  --toastify-z-index: 9999;
  --toastify-text-color-light: #757575;
  --toastify-text-color-dark: #fff;
  --toastify-text-color-info: #fff;
  --toastify-text-color-success: #fff;
  --toastify-text-color-warning: #fff;
  --toastify-text-color-error: #fff;
  --toastify-spinner-color: #616161;
  --toastify-spinner-color-empty-area: #e0e0e0;
  --toastify-color-progress-light: linear-gradient(to right, #4cd964, #5ac8fa, #007aff, #34aadc, #5856d6, #ff2d55);
  --toastify-color-progress-dark: #bb86fc;
  --toastify-color-progress-info: var(--toastify-color-info);
  --toastify-color-progress-success: var(--toastify-color-success);
  --toastify-color-progress-warning: var(--toastify-color-warning);
  --toastify-color-progress-error: var(--toastify-color-error);
}

.Toastify__toast-container {
  z-index: var(--toastify-z-index);
  -webkit-transform: translate3d(0, 0, var(--toastify-z-index));
  width: var(--toastify-toast-width);
  box-sizing: border-box;
  color: #fff;
  padding: 4px;
  position: fixed;
}

.Toastify__toast-container--top-left {
  top: 1em;
  left: 1em;
}

.Toastify__toast-container--top-center {
  top: 1em;
  left: 50%;
  transform: translateX(-50%);
}

.Toastify__toast-container--top-right {
  top: 1em;
  right: 1em;
}

.Toastify__toast-container--bottom-left {
  bottom: 1em;
  left: 1em;
}

.Toastify__toast-container--bottom-center {
  bottom: 1em;
  left: 50%;
  transform: translateX(-50%);
}

.Toastify__toast-container--bottom-right {
  bottom: 1em;
  right: 1em;
}

@media only screen and (width <= 480px) {
  .Toastify__toast-container {
    width: 100vw;
    margin: 0;
    padding: 0;
    left: 0;
  }

  .Toastify__toast-container--top-left, .Toastify__toast-container--top-center, .Toastify__toast-container--top-right {
    top: 0;
    transform: translateX(0);
  }

  .Toastify__toast-container--bottom-left, .Toastify__toast-container--bottom-center, .Toastify__toast-container--bottom-right {
    bottom: 0;
    transform: translateX(0);
  }

  .Toastify__toast-container--rtl {
    right: 0;
    left: initial;
  }
}

.Toastify__toast {
  min-height: var(--toastify-toast-min-height);
  box-sizing: border-box;
  max-height: var(--toastify-toast-max-height);
  font-family: var(--toastify-font-family);
  cursor: default;
  direction: ltr;
  z-index: 0;
  border-radius: 4px;
  justify-content: space-between;
  margin-bottom: 1rem;
  padding: 8px;
  display: flex;
  position: relative;
  overflow: hidden;
  box-shadow: 0 1px 10px #0000001a, 0 2px 15px #0000000d;
}

.Toastify__toast--rtl {
  direction: rtl;
}

.Toastify__toast--close-on-click {
  cursor: pointer;
}

.Toastify__toast-body {
  flex: auto;
  align-items: center;
  margin: auto 0;
  padding: 6px;
  display: flex;
}

.Toastify__toast-body > div:last-child {
  word-break: break-word;
  flex: 1;
}

.Toastify__toast-icon {
  -webkit-margin-end: 10px;
  flex-shrink: 0;
  width: 20px;
  margin-inline-end: 10px;
  display: flex;
}

.Toastify--animate {
  animation-duration: .7s;
  animation-fill-mode: both;
}

.Toastify--animate-icon {
  animation-duration: .3s;
  animation-fill-mode: both;
}

@media only screen and (width <= 480px) {
  .Toastify__toast {
    border-radius: 0;
    margin-bottom: 0;
  }
}

.Toastify__toast-theme--dark {
  background: var(--toastify-color-dark);
  color: var(--toastify-text-color-dark);
}

.Toastify__toast-theme--light, .Toastify__toast-theme--colored.Toastify__toast--default {
  background: var(--toastify-color-light);
  color: var(--toastify-text-color-light);
}

.Toastify__toast-theme--colored.Toastify__toast--info {
  color: var(--toastify-text-color-info);
  background: var(--toastify-color-info);
}

.Toastify__toast-theme--colored.Toastify__toast--success {
  color: var(--toastify-text-color-success);
  background: var(--toastify-color-success);
}

.Toastify__toast-theme--colored.Toastify__toast--warning {
  color: var(--toastify-text-color-warning);
  background: var(--toastify-color-warning);
}

.Toastify__toast-theme--colored.Toastify__toast--error {
  color: var(--toastify-text-color-error);
  background: var(--toastify-color-error);
}

.Toastify__progress-bar-theme--light {
  background: var(--toastify-color-progress-light);
}

.Toastify__progress-bar-theme--dark {
  background: var(--toastify-color-progress-dark);
}

.Toastify__progress-bar--info {
  background: var(--toastify-color-progress-info);
}

.Toastify__progress-bar--success {
  background: var(--toastify-color-progress-success);
}

.Toastify__progress-bar--warning {
  background: var(--toastify-color-progress-warning);
}

.Toastify__progress-bar--error {
  background: var(--toastify-color-progress-error);
}

.Toastify__progress-bar-theme--colored.Toastify__progress-bar--info, .Toastify__progress-bar-theme--colored.Toastify__progress-bar--success, .Toastify__progress-bar-theme--colored.Toastify__progress-bar--warning, .Toastify__progress-bar-theme--colored.Toastify__progress-bar--error {
  background: var(--toastify-color-transparent);
}

.Toastify__close-button {
  color: #fff;
  cursor: pointer;
  opacity: .7;
  background: none;
  border: none;
  outline: none;
  align-self: flex-start;
  padding: 0;
  transition: all .3s;
}

.Toastify__close-button--light {
  color: #000;
  opacity: .3;
}

.Toastify__close-button > svg {
  fill: currentColor;
  width: 14px;
  height: 16px;
}

.Toastify__close-button:hover, .Toastify__close-button:focus {
  opacity: 1;
}

@keyframes Toastify__trackProgress {
  0% {
    transform: scaleX(1);
  }

  100% {
    transform: scaleX(0);
  }
}

.Toastify__progress-bar {
  z-index: var(--toastify-z-index);
  opacity: .7;
  transform-origin: 0;
  width: 100%;
  height: 5px;
  position: absolute;
  bottom: 0;
  left: 0;
}

.Toastify__progress-bar--animated {
  animation: linear forwards Toastify__trackProgress;
}

.Toastify__progress-bar--controlled {
  transition: transform .2s;
}

.Toastify__progress-bar--rtl {
  right: 0;
  left: initial;
  transform-origin: 100%;
}

.Toastify__spinner {
  box-sizing: border-box;
  border: 2px solid;
  border-color: var(--toastify-spinner-color-empty-area);
  border-right-color: var(--toastify-spinner-color);
  border-radius: 100%;
  width: 20px;
  height: 20px;
  animation: .65s linear infinite Toastify__spin;
}

@keyframes Toastify__bounceInRight {
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
  }

  from {
    opacity: 0;
    transform: translate3d(3000px, 0, 0);
  }

  60% {
    opacity: 1;
    transform: translate3d(-25px, 0, 0);
  }

  75% {
    transform: translate3d(10px, 0, 0);
  }

  90% {
    transform: translate3d(-5px, 0, 0);
  }

  to {
    transform: none;
  }
}

@keyframes Toastify__bounceOutRight {
  20% {
    opacity: 1;
    transform: translate3d(-20px, 0, 0);
  }

  to {
    opacity: 0;
    transform: translate3d(2000px, 0, 0);
  }
}

@keyframes Toastify__bounceInLeft {
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
  }

  0% {
    opacity: 0;
    transform: translate3d(-3000px, 0, 0);
  }

  60% {
    opacity: 1;
    transform: translate3d(25px, 0, 0);
  }

  75% {
    transform: translate3d(-10px, 0, 0);
  }

  90% {
    transform: translate3d(5px, 0, 0);
  }

  to {
    transform: none;
  }
}

@keyframes Toastify__bounceOutLeft {
  20% {
    opacity: 1;
    transform: translate3d(20px, 0, 0);
  }

  to {
    opacity: 0;
    transform: translate3d(-2000px, 0, 0);
  }
}

@keyframes Toastify__bounceInUp {
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
  }

  from {
    opacity: 0;
    transform: translate3d(0, 3000px, 0);
  }

  60% {
    opacity: 1;
    transform: translate3d(0, -20px, 0);
  }

  75% {
    transform: translate3d(0, 10px, 0);
  }

  90% {
    transform: translate3d(0, -5px, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes Toastify__bounceOutUp {
  20% {
    transform: translate3d(0, -10px, 0);
  }

  40%, 45% {
    opacity: 1;
    transform: translate3d(0, 20px, 0);
  }

  to {
    opacity: 0;
    transform: translate3d(0, -2000px, 0);
  }
}

@keyframes Toastify__bounceInDown {
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
  }

  0% {
    opacity: 0;
    transform: translate3d(0, -3000px, 0);
  }

  60% {
    opacity: 1;
    transform: translate3d(0, 25px, 0);
  }

  75% {
    transform: translate3d(0, -10px, 0);
  }

  90% {
    transform: translate3d(0, 5px, 0);
  }

  to {
    transform: none;
  }
}

@keyframes Toastify__bounceOutDown {
  20% {
    transform: translate3d(0, 10px, 0);
  }

  40%, 45% {
    opacity: 1;
    transform: translate3d(0, -20px, 0);
  }

  to {
    opacity: 0;
    transform: translate3d(0, 2000px, 0);
  }
}

.Toastify__bounce-enter--top-left, .Toastify__bounce-enter--bottom-left {
  animation-name: Toastify__bounceInLeft;
}

.Toastify__bounce-enter--top-right, .Toastify__bounce-enter--bottom-right {
  animation-name: Toastify__bounceInRight;
}

.Toastify__bounce-enter--top-center {
  animation-name: Toastify__bounceInDown;
}

.Toastify__bounce-enter--bottom-center {
  animation-name: Toastify__bounceInUp;
}

.Toastify__bounce-exit--top-left, .Toastify__bounce-exit--bottom-left {
  animation-name: Toastify__bounceOutLeft;
}

.Toastify__bounce-exit--top-right, .Toastify__bounce-exit--bottom-right {
  animation-name: Toastify__bounceOutRight;
}

.Toastify__bounce-exit--top-center {
  animation-name: Toastify__bounceOutUp;
}

.Toastify__bounce-exit--bottom-center {
  animation-name: Toastify__bounceOutDown;
}

@keyframes Toastify__zoomIn {
  from {
    opacity: 0;
    transform: scale3d(.3, .3, .3);
  }

  50% {
    opacity: 1;
  }
}

@keyframes Toastify__zoomOut {
  from {
    opacity: 1;
  }

  50% {
    opacity: 0;
    transform: scale3d(.3, .3, .3);
  }

  to {
    opacity: 0;
  }
}

.Toastify__zoom-enter {
  animation-name: Toastify__zoomIn;
}

.Toastify__zoom-exit {
  animation-name: Toastify__zoomOut;
}

@keyframes Toastify__flipIn {
  from {
    opacity: 0;
    animation-timing-function: ease-in;
    transform: perspective(400px)rotate3d(1, 0, 0, 90deg);
  }

  40% {
    animation-timing-function: ease-in;
    transform: perspective(400px)rotate3d(1, 0, 0, -20deg);
  }

  60% {
    opacity: 1;
    transform: perspective(400px)rotate3d(1, 0, 0, 10deg);
  }

  80% {
    transform: perspective(400px)rotate3d(1, 0, 0, -5deg);
  }

  to {
    transform: perspective(400px);
  }
}

@keyframes Toastify__flipOut {
  from {
    transform: perspective(400px);
  }

  30% {
    opacity: 1;
    transform: perspective(400px)rotate3d(1, 0, 0, -20deg);
  }

  to {
    opacity: 0;
    transform: perspective(400px)rotate3d(1, 0, 0, 90deg);
  }
}

.Toastify__flip-enter {
  animation-name: Toastify__flipIn;
}

.Toastify__flip-exit {
  animation-name: Toastify__flipOut;
}

@keyframes Toastify__slideInRight {
  from {
    visibility: visible;
    transform: translate3d(110%, 0, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes Toastify__slideInLeft {
  from {
    visibility: visible;
    transform: translate3d(-110%, 0, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes Toastify__slideInUp {
  from {
    visibility: visible;
    transform: translate3d(0, 110%, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes Toastify__slideInDown {
  from {
    visibility: visible;
    transform: translate3d(0, -110%, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes Toastify__slideOutRight {
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    transform: translate3d(110%, 0, 0);
  }
}

@keyframes Toastify__slideOutLeft {
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    transform: translate3d(-110%, 0, 0);
  }
}

@keyframes Toastify__slideOutDown {
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    transform: translate3d(0, 500px, 0);
  }
}

@keyframes Toastify__slideOutUp {
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    transform: translate3d(0, -500px, 0);
  }
}

.Toastify__slide-enter--top-left, .Toastify__slide-enter--bottom-left {
  animation-name: Toastify__slideInLeft;
}

.Toastify__slide-enter--top-right, .Toastify__slide-enter--bottom-right {
  animation-name: Toastify__slideInRight;
}

.Toastify__slide-enter--top-center {
  animation-name: Toastify__slideInDown;
}

.Toastify__slide-enter--bottom-center {
  animation-name: Toastify__slideInUp;
}

.Toastify__slide-exit--top-left, .Toastify__slide-exit--bottom-left {
  animation-name: Toastify__slideOutLeft;
}

.Toastify__slide-exit--top-right, .Toastify__slide-exit--bottom-right {
  animation-name: Toastify__slideOutRight;
}

.Toastify__slide-exit--top-center {
  animation-name: Toastify__slideOutUp;
}

.Toastify__slide-exit--bottom-center {
  animation-name: Toastify__slideOutDown;
}

@keyframes Toastify__spin {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

.multi-range-slider * {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.multi-range-slider {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
  -khtml-user-select: none;
  border: 1px solid gray;
  border-radius: 10px;
  flex-direction: column;
  padding: 20px 10px;
  display: flex;
  position: relative;
  box-shadow: 1px 1px 4px #000;
}

.multi-range-slider .bar {
  display: flex;
}

.multi-range-slider .bar * {
  transition: none !important;
}

.multi-range-slider .bar.active * {
  transition: none;
}

.multi-range-slider .bar-left {
  box-shadow: none;
  background-color: #f8f7f4;
  border-radius: 10px 0 0 10px;
  width: 25%;
  padding: 4px 0;
}

.multi-range-slider .bar-right {
  background-color: #f0f0f0;
  border-radius: 0 10px 10px 0;
  width: 25%;
  box-shadow: inset 0 0 5px #000;
}

.multi-range-slider .bar-inner {
  background-color: #0f0;
  border: 1px solid #000;
  flex-grow: 1;
  flex-shrink: 1;
  justify-content: space-between;
  display: flex;
  position: relative;
  box-shadow: inset 0 0 5px #000;
}

.multi-range-slider .bar-inner-left, .multi-range-slider .bar-inner-right {
  width: 50%;
}

.multi-range-slider .input-type-range:focus + .thumb:after {
  content: "";
  z-index: 2;
  box-shadow: none;
  border: none;
  border-radius: 50%;
  width: 11px;
  height: 11px;
  position: absolute;
  top: -4px;
  left: -4px;
}

.multi-range-slider .thumb * {
  text-align: center;
  color: #00778b;
  background-color: #f8f7f4;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  font-size: 75%;
  line-height: 30px;
  position: absolute;
  bottom: 25px;
  left: -15px;
  display: none !important;
  box-shadow: 0 1px 3px #0000001f, 0 1px 2px #0000003d !important;
}

.multi-range-slider .label {
  justify-content: space-between;
  margin-top: 10px;
  margin-bottom: -20px;
  padding: 0;
  display: flex;
}

.multi-range-slider .label-min, .multi-range-slider .label-max {
  font-size: 80%;
}

.multi-range-slider {
  box-shadow: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
  -khtml-user-select: none;
  border: none;
  border-radius: 0;
  flex-direction: column;
  padding: 0;
  display: flex;
  position: relative;
}

.multi-range-slider .bar-right {
  box-shadow: none;
  background-color: #f8f7f4;
  border-radius: 0 10px 10px 0;
  width: 25%;
}

.multi-range-slider .bar-inner {
  box-shadow: none;
  border: none;
  flex-grow: 1;
  flex-shrink: 1;
  justify-content: space-between;
  display: flex;
  position: relative;
  background-color: #4450ce !important;
}

.multi-range-slider .bar-inner-left, .multi-range-slider .bar-inner-right {
  width: 50%;
}

.multi-range-slider .thumb {
  z-index: 1;
  cursor: pointer;
  background-color: red;
  position: relative;
}

.multi-range-slider .thumb:before {
  content: "";
  z-index: 1;
  cursor: pointer;
  background-color: #fff;
  border: 1px solid #343a40;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  margin: -8px;
  position: absolute;
  box-shadow: 0 1px 3px #0000001f, 0 1px 2px #0000003d;
}

.multi-range-slider .thumb:active * {
  display: block;
}

.input-type-range {
  opacity: 0;
  pointer-events: none;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.multi-range-slider .ruler {
  margin: 10px 0 -5px;
  display: flex;
}

.multi-range-slider .ruler .ruler-rule {
  border-bottom: 1px solid #343a40;
  border-left: 1px solid #343a40;
  flex-grow: 1;
  flex-shrink: 1;
  padding: 5px 0;
  display: flex;
}

.multi-range-slider .ruler .ruler-rule:last-child {
  border-right: 1px solid #343a40;
}

.multi-range-slider .label {
  display: none;
}

.multi-range-slider-black * {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.multi-range-slider-black {
  color: #fff;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
  -khtml-user-select: none;
  background-color: #000;
  border: 1px solid gray;
  flex-direction: column;
  padding: 30px 10px 25px;
  display: flex;
  position: relative;
}

.multi-range-slider-black .bar {
  display: flex;
}

.multi-range-slider-black .bar * {
  transition: all .1s;
}

.multi-range-slider-black .bar.active * {
  transition: none;
}

.multi-range-slider-black .bar-left {
  background-color: gray;
  border: 1px solid #000;
  border-radius: 10px 0 0 10px;
  width: 25%;
  padding: 5px 0;
  box-shadow: inset 0 0 5px #000;
}

.multi-range-slider-black .bar-right {
  background-color: gray;
  border: 1px solid #000;
  border-radius: 0 10px 10px 0;
  width: 25%;
  box-shadow: inset 0 0 5px #000;
}

.multi-range-slider-black .bar-inner {
  background-color: #0f0;
  border: 1px solid #000;
  flex-grow: 1;
  flex-shrink: 1;
  justify-content: space-between;
  display: flex;
  position: relative;
  box-shadow: inset 0 0 5px #000;
}

.multi-range-slider-black .bar-inner-left, .multi-range-slider-black .bar-inner-right {
  width: 50%;
}

.multi-range-slider-black .thumb {
  z-index: 1;
  cursor: pointer;
  background-color: red;
  position: relative;
}

.multi-range-slider-black .thumb:before {
  content: "";
  z-index: 1;
  cursor: pointer;
  background-color: #fff;
  border: 1px solid #000;
  border-radius: 7px;
  width: 20px;
  height: 30px;
  margin: -10px;
  position: absolute;
  box-shadow: 0 0 5px #000, inset 0 0 10px gray;
}

.multi-range-slider-black .input-type-range:focus + .thumb:after {
  content: "";
  z-index: 2;
  border: 1px dotted #000;
  border-radius: 7px;
  width: 12px;
  height: 22px;
  position: absolute;
  top: -6px;
  left: -6px;
  box-shadow: 0 0 5px #fff, inset 0 0 10px #000;
}

.multi-range-slider-black .thumb * {
  text-align: center;
  color: #fff;
  background-color: #00f;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  font-size: 75%;
  line-height: 30px;
  position: absolute;
  bottom: 25px;
  left: -15px;
  box-shadow: 0 0 5px #000;
}

.multi-range-slider-black .thumb:active * {
  display: block;
}

.input-type-range {
  opacity: 0;
  pointer-events: none;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.multi-range-slider-black .ruler {
  margin: 10px 0 -5px;
  display: flex;
}

.multi-range-slider-black .ruler .ruler-rule {
  border-bottom: 1px solid;
  border-left: 1px solid;
  flex-grow: 1;
  flex-shrink: 1;
  padding: 5px 0;
  display: flex;
}

.multi-range-slider-black .ruler .ruler-rule:last-child {
  border-right: 1px solid;
}

.multi-range-slider-black .label {
  justify-content: space-between;
  margin-top: 10px;
  margin-bottom: -20px;
  padding: 0;
  display: flex;
}

.multi-range-slider-black .label-min, .multi-range-slider-black .label-max {
  font-size: 80%;
}

.pretty * {
  box-sizing: border-box;
}

.pretty input:not([type="checkbox"]):not([type="radio"]) {
  display: none;
}

.pretty {
  white-space: nowrap;
  margin-right: 1em;
  line-height: 1;
  display: inline-block;
  position: relative;
}

.pretty input {
  z-index: 2;
  opacity: 0;
  cursor: pointer;
  width: 100%;
  min-width: 1em;
  height: 100%;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 0;
  left: 0;
}

.pretty .state label {
  position: initial;
  text-indent: 1.5em;
  min-width: calc(1em + 2px);
  margin: 0;
  font-weight: 400;
  display: inline-block;
}

.pretty .state label:after, .pretty .state label:before {
  content: "";
  box-sizing: border-box;
  z-index: 0;
  background-color: #0000;
  border: 1px solid #0000;
  border-radius: 0;
  width: calc(1em + 2px);
  height: calc(1em + 2px);
  display: block;
  position: absolute;
  top: calc(1em - 108%);
  left: 0;
}

.pretty .state label:before {
  border-color: #bdc3c7;
}

.pretty .state.p-is-hover, .pretty .state.p-is-indeterminate {
  display: none;
}

@keyframes zoom {
  0% {
    opacity: 0;
    transform: scale(0);
  }
}

@keyframes tada {
  0% {
    opacity: 0;
    animation-timing-function: ease-in;
    transform: scale(7);
  }

  38% {
    opacity: 1;
    animation-timing-function: ease-out;
    transform: scale(1);
  }

  55% {
    animation-timing-function: ease-in;
    transform: scale(1.5);
  }

  72% {
    animation-timing-function: ease-out;
    transform: scale(1);
  }

  81% {
    animation-timing-function: ease-in;
    transform: scale(1.24);
  }

  89% {
    animation-timing-function: ease-out;
    transform: scale(1);
  }

  95% {
    animation-timing-function: ease-in;
    transform: scale(1.04);
  }

  100% {
    animation-timing-function: ease-out;
    transform: scale(1);
  }
}

@keyframes jelly {
  0% {
    transform: scale3d(1, 1, 1);
  }

  30% {
    transform: scale3d(.75, 1.25, 1);
  }

  40% {
    transform: scale3d(1.25, .75, 1);
  }

  50% {
    transform: scale3d(.85, 1.15, 1);
  }

  65% {
    transform: scale3d(1.05, .95, 1);
  }

  75% {
    transform: scale3d(.95, 1.05, 1);
  }

  100% {
    transform: scale3d(1, 1, 1);
  }
}

@keyframes rotate {
  0% {
    opacity: 0;
    transform: translateZ(-200px)rotate(-45deg);
  }

  100% {
    opacity: 1;
    transform: translateZ(0)rotate(0);
  }
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 #bdc3c7;
  }

  100% {
    box-shadow: 0 0 0 1.5em #bdc3c700;
  }
}

.pretty.p-default.p-fill .state label:after {
  transform: scale(1);
}

.pretty.p-default .state label:after {
  transform: scale(.6);
}

.pretty.p-default input:checked ~ .state label:after {
  background-color: #bdc3c7 !important;
}

.pretty.p-default.p-thick .state label:after, .pretty.p-default.p-thick .state label:before {
  border-width: .142857em;
}

.pretty.p-default.p-thick .state label:after {
  transform: scale(.4) !important;
}

.pretty.p-icon .state .icon {
  z-index: 1;
  text-align: center;
  opacity: 0;
  border: 1px solid #0000;
  width: calc(1em + 2px);
  height: calc(1em + 2px);
  font-size: 1em;
  line-height: normal;
  position: absolute;
  top: calc(1em - 108%);
  left: 0;
}

.pretty.p-icon .state .icon:before {
  text-align: center;
  flex: 1;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  margin: 0;
  line-height: 1;
  display: flex;
}

.pretty.p-icon input:checked ~ .state .icon, .pretty.p-icon input:indeterminate ~ .state .icon {
  opacity: 1;
}

.pretty.p-icon input:checked ~ .state label:before, .pretty.p-icon input:indeterminate ~ .state label:before {
  border-color: #5a656b;
}

.pretty.p-svg .state .svg {
  z-index: 1;
  text-align: center;
  opacity: 0;
  border: 1px solid #0000;
  width: calc(1em + 2px);
  height: calc(1em + 2px);
  font-size: 1em;
  line-height: normal;
  position: absolute;
  top: calc(1em - 108%);
  left: 0;
}

.pretty.p-svg .state svg {
  text-align: center;
  flex: 1;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  margin: 0;
  line-height: 1;
  display: flex;
}

.pretty.p-svg input:checked ~ .state .svg, .pretty.p-svg input:indeterminate ~ .state .svg {
  opacity: 1;
}

.pretty.p-image .state img {
  opacity: 0;
  z-index: 0;
  text-align: center;
  width: calc(1em + 2px);
  height: calc(1em + 2px);
  line-height: normal;
  position: absolute;
  top: calc(1em - 108%);
  left: 0;
  transform: scale(.8);
}

.pretty.p-image input:checked ~ .state img, .pretty.p-image input:indeterminate ~ .state img {
  opacity: 1;
}

.pretty.p-switch input {
  min-width: 2em;
}

.pretty.p-switch .state {
  position: relative;
}

.pretty.p-switch .state:before {
  content: "";
  -webkit-box-sizing: unset;
  box-sizing: unset;
  z-index: 0;
  -o-transition: all .5s ease;
  border: 1px solid #bdc3c7;
  border-radius: 60px;
  width: 2em;
  height: calc(1em + 2px);
  transition: all .5s;
  position: absolute;
  top: calc(1em - 116%);
}

.pretty.p-switch .state label {
  text-indent: 2.5em;
}

.pretty.p-switch .state label:after, .pretty.p-switch .state label:before {
  -o-transition: all .5s ease;
  border-color: #0000;
  border-radius: 100%;
  transition: all .5s;
  left: 0;
  transform: scale(.8);
}

.pretty.p-switch .state label:after {
  background-color: #bdc3c7 !important;
}

.pretty.p-switch input:checked ~ .state:before {
  border-color: #5a656b;
}

.pretty.p-switch input:checked ~ .state label:before {
  opacity: 0;
}

.pretty.p-switch input:checked ~ .state label:after {
  left: 1em;
  background-color: #5a656b !important;
}

.pretty.p-switch.p-fill input:checked ~ .state:before {
  border-color: #5a656b;
  background-color: #5a656b !important;
}

.pretty.p-switch.p-fill input:checked ~ .state label:before {
  opacity: 0;
}

.pretty.p-switch.p-fill input:checked ~ .state label:after {
  left: 1em;
  background-color: #fff !important;
}

.pretty.p-switch.p-slim .state:before {
  height: .1em;
  top: calc(50% - .1em);
  background: #bdc3c7 !important;
}

.pretty.p-switch.p-slim input:checked ~ .state:before {
  border-color: #5a656b;
  background-color: #5a656b !important;
}

.pretty.p-has-hover input:hover ~ .state:not(.p-is-hover) {
  display: none;
}

.pretty.p-has-hover input:hover ~ .state.p-is-hover, .pretty.p-has-hover input:hover ~ .state.p-is-hover .icon {
  display: block;
}

.pretty.p-has-focus input:focus ~ .state label:before {
  box-shadow: 0 0 3px #bdc3c7;
}

.pretty.p-has-indeterminate input[type="checkbox"]:indeterminate ~ .state:not(.p-is-indeterminate) {
  display: none;
}

.pretty.p-has-indeterminate input[type="checkbox"]:indeterminate ~ .state.p-is-indeterminate {
  display: block;
}

.pretty.p-has-indeterminate input[type="checkbox"]:indeterminate ~ .state.p-is-indeterminate .icon {
  opacity: 1;
  display: block;
}

.pretty.p-toggle .state.p-on {
  opacity: 0;
  display: none;
}

.pretty.p-toggle .state .icon, .pretty.p-toggle .state .svg, .pretty.p-toggle .state img, .pretty.p-toggle .state.p-off {
  opacity: 1;
  display: inherit;
}

.pretty.p-toggle .state.p-off .icon {
  color: #bdc3c7;
}

.pretty.p-toggle input:checked ~ .state.p-on {
  opacity: 1;
  display: inherit;
}

.pretty.p-toggle input:checked ~ .state.p-off {
  opacity: 0;
  display: none;
}

.pretty.p-plain input:checked ~ .state label:before, .pretty.p-plain.p-toggle .state label:before {
  content: none;
}

.pretty.p-plain.p-plain .icon {
  transform: scale(1.1);
}

.pretty.p-round .state label:after, .pretty.p-round .state label:before {
  border-radius: 100%;
}

.pretty.p-round.p-icon .state .icon {
  border-radius: 100%;
  overflow: hidden;
}

.pretty.p-round.p-icon .state .icon:before {
  transform: scale(.8);
}

.pretty.p-curve .state label:after, .pretty.p-curve .state label:before {
  border-radius: 20%;
}

.pretty.p-smooth .icon, .pretty.p-smooth .svg, .pretty.p-smooth label:after, .pretty.p-smooth label:before {
  -o-transition: all .5s ease;
  transition: all .5s;
}

.pretty.p-smooth input:checked + .state label:after {
  -o-transition: all .3s ease;
  transition: all .3s;
}

.pretty.p-smooth input:checked + .state .icon, .pretty.p-smooth input:checked + .state .svg, .pretty.p-smooth input:checked + .state img, .pretty.p-smooth.p-default input:checked + .state label:after {
  animation: .2s zoom;
}

.pretty.p-smooth.p-plain input:checked + .state label:before {
  content: "";
  -o-transition: all .5s ease;
  transition: all .5s;
  transform: scale(0);
}

.pretty.p-tada:not(.p-default) input:checked + .state .icon, .pretty.p-tada:not(.p-default) input:checked + .state .svg, .pretty.p-tada:not(.p-default) input:checked + .state img, .pretty.p-tada:not(.p-default) input:checked + .state label:after, .pretty.p-tada:not(.p-default) input:checked + .state label:before {
  opacity: 1;
  animation: .7s cubic-bezier(.25, .46, .45, .94) alternate tada;
}

.pretty.p-jelly:not(.p-default) input:checked + .state .icon, .pretty.p-jelly:not(.p-default) input:checked + .state .svg, .pretty.p-jelly:not(.p-default) input:checked + .state img, .pretty.p-jelly:not(.p-default) input:checked + .state label:after, .pretty.p-jelly:not(.p-default) input:checked + .state label:before {
  opacity: 1;
  animation: .7s cubic-bezier(.25, .46, .45, .94) jelly;
}

.pretty.p-jelly:not(.p-default) input:checked + .state label:before {
  border-color: #0000;
}

.pretty.p-rotate:not(.p-default) input:checked ~ .state .icon, .pretty.p-rotate:not(.p-default) input:checked ~ .state .svg, .pretty.p-rotate:not(.p-default) input:checked ~ .state img, .pretty.p-rotate:not(.p-default) input:checked ~ .state label:after, .pretty.p-rotate:not(.p-default) input:checked ~ .state label:before {
  opacity: 1;
  animation: .7s cubic-bezier(.25, .46, .45, .94) rotate;
}

.pretty.p-rotate:not(.p-default) input:checked ~ .state label:before {
  border-color: #0000;
}

.pretty.p-pulse:not(.p-switch) input:checked ~ .state label:before {
  animation: 1s pulse;
}

.pretty input[disabled] {
  cursor: not-allowed;
  display: none;
}

.pretty input[disabled] ~ * {
  opacity: .5;
}

.pretty.p-locked input {
  cursor: not-allowed;
  display: none;
}

.pretty input:checked ~ .state.p-primary label:after, .pretty.p-toggle .state.p-primary label:after {
  background-color: #4450ce !important;
}

.pretty input:checked ~ .state.p-primary .icon, .pretty input:checked ~ .state.p-primary .svg, .pretty.p-toggle .state.p-primary .icon, .pretty.p-toggle .state.p-primary .svg {
  color: #fff;
  stroke: #fff;
}

.pretty input:checked ~ .state.p-primary-o label:before, .pretty.p-toggle .state.p-primary-o label:before {
  border-color: #4450ce;
}

.pretty input:checked ~ .state.p-primary-o label:after, .pretty.p-toggle .state.p-primary-o label:after {
  background-color: #0000;
}

.pretty input:checked ~ .state.p-primary-o .icon, .pretty input:checked ~ .state.p-primary-o .svg, .pretty input:checked ~ .state.p-primary-o svg, .pretty.p-toggle .state.p-primary-o .icon, .pretty.p-toggle .state.p-primary-o .svg, .pretty.p-toggle .state.p-primary-o svg {
  color: #4450ce;
  stroke: #4450ce;
}

.pretty.p-default:not(.p-fill) input:checked ~ .state.p-primary-o label:after {
  background-color: #4450ce !important;
}

.pretty.p-switch input:checked ~ .state.p-primary:before {
  border-color: #4450ce;
}

.pretty.p-switch.p-fill input:checked ~ .state.p-primary:before {
  background-color: #4450ce !important;
}

.pretty.p-switch.p-slim input:checked ~ .state.p-primary:before {
  border-color: #245682;
  background-color: #245682 !important;
}

.pretty input:checked ~ .state.p-info label:after, .pretty.p-toggle .state.p-info label:after {
  background-color: #5bc0de !important;
}

.pretty input:checked ~ .state.p-info .icon, .pretty input:checked ~ .state.p-info .svg, .pretty.p-toggle .state.p-info .icon, .pretty.p-toggle .state.p-info .svg {
  color: #fff;
  stroke: #fff;
}

.pretty input:checked ~ .state.p-info-o label:before, .pretty.p-toggle .state.p-info-o label:before {
  border-color: #5bc0de;
}

.pretty input:checked ~ .state.p-info-o label:after, .pretty.p-toggle .state.p-info-o label:after {
  background-color: #0000;
}

.pretty input:checked ~ .state.p-info-o .icon, .pretty input:checked ~ .state.p-info-o .svg, .pretty input:checked ~ .state.p-info-o svg, .pretty.p-toggle .state.p-info-o .icon, .pretty.p-toggle .state.p-info-o .svg, .pretty.p-toggle .state.p-info-o svg {
  color: #5bc0de;
  stroke: #5bc0de;
}

.pretty.p-default:not(.p-fill) input:checked ~ .state.p-info-o label:after {
  background-color: #5bc0de !important;
}

.pretty.p-switch input:checked ~ .state.p-info:before {
  border-color: #5bc0de;
}

.pretty.p-switch.p-fill input:checked ~ .state.p-info:before {
  background-color: #5bc0de !important;
}

.pretty.p-switch.p-slim input:checked ~ .state.p-info:before {
  border-color: #2390b0;
  background-color: #2390b0 !important;
}

.pretty input:checked ~ .state.p-success label:after, .pretty.p-toggle .state.p-success label:after {
  background-color: #5cb85c !important;
}

.pretty input:checked ~ .state.p-success .icon, .pretty input:checked ~ .state.p-success .svg, .pretty.p-toggle .state.p-success .icon, .pretty.p-toggle .state.p-success .svg {
  color: #fff;
  stroke: #fff;
}

.pretty input:checked ~ .state.p-success-o label:before, .pretty.p-toggle .state.p-success-o label:before {
  border-color: #5cb85c;
}

.pretty input:checked ~ .state.p-success-o label:after, .pretty.p-toggle .state.p-success-o label:after {
  background-color: #0000;
}

.pretty input:checked ~ .state.p-success-o .icon, .pretty input:checked ~ .state.p-success-o .svg, .pretty input:checked ~ .state.p-success-o svg, .pretty.p-toggle .state.p-success-o .icon, .pretty.p-toggle .state.p-success-o .svg, .pretty.p-toggle .state.p-success-o svg {
  color: #5cb85c;
  stroke: #5cb85c;
}

.pretty.p-default:not(.p-fill) input:checked ~ .state.p-success-o label:after {
  background-color: #5cb85c !important;
}

.pretty.p-switch input:checked ~ .state.p-success:before {
  border-color: #5cb85c;
}

.pretty.p-switch.p-fill input:checked ~ .state.p-success:before {
  background-color: #5cb85c !important;
}

.pretty.p-switch.p-slim input:checked ~ .state.p-success:before {
  border-color: #357935;
  background-color: #357935 !important;
}

.pretty input:checked ~ .state.p-warning label:after, .pretty.p-toggle .state.p-warning label:after {
  background-color: #f0ad4e !important;
}

.pretty input:checked ~ .state.p-warning .icon, .pretty input:checked ~ .state.p-warning .svg, .pretty.p-toggle .state.p-warning .icon, .pretty.p-toggle .state.p-warning .svg {
  color: #fff;
  stroke: #fff;
}

.pretty input:checked ~ .state.p-warning-o label:before, .pretty.p-toggle .state.p-warning-o label:before {
  border-color: #f0ad4e;
}

.pretty input:checked ~ .state.p-warning-o label:after, .pretty.p-toggle .state.p-warning-o label:after {
  background-color: #0000;
}

.pretty input:checked ~ .state.p-warning-o .icon, .pretty input:checked ~ .state.p-warning-o .svg, .pretty input:checked ~ .state.p-warning-o svg, .pretty.p-toggle .state.p-warning-o .icon, .pretty.p-toggle .state.p-warning-o .svg, .pretty.p-toggle .state.p-warning-o svg {
  color: #f0ad4e;
  stroke: #f0ad4e;
}

.pretty.p-default:not(.p-fill) input:checked ~ .state.p-warning-o label:after {
  background-color: #f0ad4e !important;
}

.pretty.p-switch input:checked ~ .state.p-warning:before {
  border-color: #f0ad4e;
}

.pretty.p-switch.p-fill input:checked ~ .state.p-warning:before {
  background-color: #f0ad4e !important;
}

.pretty.p-switch.p-slim input:checked ~ .state.p-warning:before {
  border-color: #c77c11;
  background-color: #c77c11 !important;
}

.pretty input:checked ~ .state.p-danger label:after, .pretty.p-toggle .state.p-danger label:after {
  background-color: #d9534f !important;
}

.pretty input:checked ~ .state.p-danger .icon, .pretty input:checked ~ .state.p-danger .svg, .pretty.p-toggle .state.p-danger .icon, .pretty.p-toggle .state.p-danger .svg {
  color: #fff;
  stroke: #fff;
}

.pretty input:checked ~ .state.p-danger-o label:before, .pretty.p-toggle .state.p-danger-o label:before {
  border-color: #d9534f;
}

.pretty input:checked ~ .state.p-danger-o label:after, .pretty.p-toggle .state.p-danger-o label:after {
  background-color: #0000;
}

.pretty input:checked ~ .state.p-danger-o .icon, .pretty input:checked ~ .state.p-danger-o .svg, .pretty input:checked ~ .state.p-danger-o svg, .pretty.p-toggle .state.p-danger-o .icon, .pretty.p-toggle .state.p-danger-o .svg, .pretty.p-toggle .state.p-danger-o svg {
  color: #d9534f;
  stroke: #d9534f;
}

.pretty.p-default:not(.p-fill) input:checked ~ .state.p-danger-o label:after {
  background-color: #d9534f !important;
}

.pretty.p-switch input:checked ~ .state.p-danger:before {
  border-color: #d9534f;
}

.pretty.p-switch.p-fill input:checked ~ .state.p-danger:before {
  background-color: #d9534f !important;
}

.pretty.p-switch.p-slim input:checked ~ .state.p-danger:before {
  border-color: #a02622;
  background-color: #a02622 !important;
}

.pretty.p-bigger .icon, .pretty.p-bigger .img, .pretty.p-bigger .svg, .pretty.p-bigger label:after, .pretty.p-bigger label:before {
  font-size: 1.2em !important;
  top: calc(1em - 135%) !important;
}

.pretty.p-bigger label {
  text-indent: 1.7em;
}

@media print {
  .pretty .state .icon, .pretty .state label:after, .pretty .state label:before, .pretty .state:before {
    color-adjust: exact;
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
  }
}

/*# sourceMappingURL=index.7fc6a59a.css.map */
